//------------------------------------------
//        Variables
//------------------------------------------
$fonts__file-path-fonts: '/Content/fonts';

$fonts__base-size: 16px;

$fonts__sans-serif: 'MuseoSans';
$fonts__serif-regular: 'Georgia';

$fonts__font-weight--light: 300;
$fonts__font-weight--regular: 400;
$fonts__font-weight--medium: 500;
$fonts__font-weight--bold: 700;
$fonts__font-weight--black: 900;

//------------------------------------------
//        Functions
//------------------------------------------

@function em($px, $base: $fonts__base-size) {
    @return ($px / $base) * 1em;
}

@function rem($px, $base: $fonts__base-size) {
    @return ($px / $base) * 1rem;
}

//------------------------------------------
//              Mixins
//------------------------------------------

@mixin font-face($font-family, $file-path, $font-style, $font-weight) {
    @font-face {
        font-family: $font-family;
        src: url('#{$fonts__file-path-fonts}/#{$file-path}.eot');
        src: url('#{$fonts__file-path-fonts}/#{$file-path}.eot?#iefix') format('embedded-opentype'),
            url('#{$fonts__file-path-fonts}/#{$file-path}.woff2') format('woff2'),
            url('#{$fonts__file-path-fonts}/#{$file-path}.woff') format('woff'),
            url('#{$fonts__file-path-fonts}/#{$file-path}.ttf') format('truetype'),
            url('#{$fonts__file-path-fonts}/#{$file-path}.svg##{$font-family}') format('svg');
        font-style: $font-style;
        font-weight: $font-weight;
    }
}

@mixin fonts__sans-serif-light () { // MuseoSans 300
    font-family: $fonts__sans-serif, 'arial', sans-serif;
    font-style: normal;
    font-weight: $fonts__font-weight--light;
}

@mixin fonts__sans-serif-regular () { // MuseoSans 500
    font-family: $fonts__sans-serif, 'arial', sans-serif;
    font-style: normal;
    font-weight: $fonts__font-weight--regular;
}

@mixin fonts__sans-serif-medium () { // MuseoSans 500
    font-family: $fonts__sans-serif, 'arial', sans-serif;
    font-style: normal;
    font-weight: $fonts__font-weight--medium;
}

@mixin fonts__sans-serif-bold () { // MuseoSans 700
    font-family: $fonts__sans-serif, 'arial', sans-serif;
    font-style: normal;
    font-weight: $fonts__font-weight--bold;
}

@mixin fonts__sans-serif-black () { // MuseoSans 900
    font-family: $fonts__sans-serif, 'arial', sans-serif;
    font-style: normal;
    font-weight: $fonts__font-weight--black;
}

@mixin fonts__serif-regular () {    // Georgia
    font-family: $fonts__serif-regular, 'times new roman', serif;
    font-style: normal;
    font-weight: $fonts__font-weight--regular;
}

//------------------------------------------
//              Font Load
//------------------------------------------

@include font-face($fonts__sans-serif, 'MuseoSans-300', normal, $fonts__font-weight--light);
@include font-face($fonts__sans-serif, 'MuseoSans-500', normal, $fonts__font-weight--medium);
@include font-face($fonts__sans-serif, 'MuseoSans-700', normal, $fonts__font-weight--bold);
@include font-face($fonts__sans-serif, 'MuseoSans-900', normal, $fonts__font-weight--black);

