.share-tools {
    position: relative;
    display: flex;
    margin-bottom: 40px;
    z-index: 1;

    &__icon {
        height: 28px;
        width: 28px;
        display: block;
        color: $colors__gray--84; 

        &:hover {
            color: $colors__blue;
        }
    }

    &__icon-svg {
        height: 28px;
        width: 28px;
        display: block;
    }

    &__social {
        background-color: $colors__white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
        padding: 8px 16px;
        position: absolute;
        left: 0;
        top: 100%;
        display: none;
        list-style-type: none;

        a {
            flex: 0 0 auto;
            display: block;
        }

    }

    &__social-item {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }

    &__share {
        flex: 0 0 auto;
        margin-right: 16px;

        &:hover {
            > ul {
                display: flex; // .share-tools__social
            }

            color: $colors__blue;
        }
    }

    &__print {
        flex: 0 0 auto;
    }

    @media print {
        display: none;
    }
}

.mobile-share {
    margin-bottom: .5em;
    @include media('>=tablet-portrait') {
        display: none;
        margin-bottom: 0;
    }
}