.clienthq-card {
    flex: 0 1 calc((100%/2) - 1em);
    margin: 0 .5em;
    background-color: $colors__white;
    border-radius: 6px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, .3);
    text-align: center;
    justify-content: center;
    padding: 1em;
    font-size: 16px;
    display: flex;
    margin-bottom: 1em;
    transition: .3s ease all;
    border-bottom: 6px solid transparent;

    &.no-img {
        .clienthq-card__link {
            justify-content: center;
        }
    }

    @include media('>=tablet-portrait') {
        flex: 0 1 calc((100%/4) - 1em);
    }

    &.disabled {
        pointer-events: none;
        background-color: $colors__gray--ee;

        a {
            color: $colors__gray--ac;
        }

        &:hover {
            border-bottom-color: transparent;
        }
    }

    &:hover {
        // transform: scale(1.05);
        border-bottom: 6px solid $colors__teal;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: #343844;

        &:hover {
            text-decoration: none;
            color: #343844;
        }
    }

    &__container {
        padding-left: 0 !important;
        list-style-type: none;
        margin: 0 -.5em;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__img {
        margin-bottom: 1em;
    }

    &__img-container {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
    }

    &__text {
        @include fonts__sans-serif-medium;

        &-title {
            margin-bottom: 10px;
        }

        &-subtitle {
            font-size: 14px;
        }
    }

    & a:hover {
        text-decoration: none;
        color: #343844;
    }
}
