.clienthq-tabs {
    width: 100%;

    &__inner {
    }

    &__nav-container {
        overflow: hidden;
    }

    &__dash-container {
        overflow: visible;
    }

    &__nav {
        padding-left: 0 !important;
        list-style-type: none;
        margin: 0 -1em 1.5em -1em;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        border-bottom: 1px solid $colors__gray--c5;
    }

    &__dash-item {
        @include font-primary;
        flex: 1 0 100%;
        margin: 0 0 0 2em;
        border-bottom: 6px solid transparent;
        transition: .3s ease all;
        line-height: 5.5;
        height: 100px;

        @include media('>=tablet-portrait') {
            flex: 0 0 auto;
        }


        &.active {
            @include font-primary-bold;
            border-bottom: 6px solid $colors__teal--dark;
            outline: none;

            a {
                color: $colors__blue--dark;
            }
        }

        &:hover, &:focus {
            @include font-primary-bold;
            outline: none;

            a {
                color: $colors__blue--dark;
            }
        }

        &:first-child {
            margin-left: 4em;
        }

        &:last-child {
            // margin-right: 0;
        }
    }

    &__nav-item {
        @include font-primary;
        flex: 1 0 100%;
        margin: 0 1em 0 1em;
        border-bottom: 6px solid transparent;
        transition: .3s ease all;

        @include media('>=tablet-portrait') {
            flex: 0 0 auto;
        }


        &.active, &:hover, &:focus {
            @include font-primary-bold;
            border-bottom: 6px solid $colors__teal--dark;
            outline: none;

            a {
                color: $colors__blue--dark;
            }
        }

        &:first-child {
            // margin-left: 0;
        }

        &:last-child {
            // margin-right: 0;
        }
    }

    &__nav-item-label {
        font-size: 18px;
        padding: .5em 0;
        display: block;
        color: $colors__gray--84;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $colors__black;
        }
    }

    &__tab {
        position: relative;
    }

    &__tab-content {
        display: none;

        &.active {
            display: block;
        }
    }
}
