.clienthq-footer {
    background: #1E2133;
    padding: 56px 0;
    width: 100%;

    &__security {
        color: #FFF;
        text-align: center;
        /* H4 */
        font-family: MuseoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 919px;
        margin: 0 auto;

        @include media('<desktop') {
            width: auto;
            padding: 0px 10px;
        }
    }

    &__security a {
        padding-top: 25px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
    }

    &__footer-logo {
        width: 46px;
        height: 46px;
        vertical-align: middle
    }

    &__footer-text {
        @include font-primary-light;
        color: $colors__white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 40px;
        text-decoration: none;
        flex-direction: row;
        gap: 2px;
    }

    &__social-share {
        display: inline-block;
        white-space: nowrap;
        margin: 14px 14px 13px 34px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        color: #ffffff;
        font-family: museo-sans;
        width: 100%;
        text-align: right;
    }


    &__width-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        max-width: 1100px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: none;
        justify-content: left;

        & a:hover {
            text-decoration: none;
            color: $colors__white;
        }
    }

    &__social-list-item {
        font-size: 1.75em !important;
        flex: 0 0 auto;
        margin-left: 8px !important;
        margin-bottom: 0 !important;
        line-height: 0;
        color: $colors__white;

        &:first-child {
            margin-left: 0 !important;
        }

        &:last-child {
            margin-right: 10px;
        }

        a {
            text-align: center;
            display: inline-block;
            position: relative;
            color: $colors__white !important;
            height: 33.25px;
            width: 28.5px;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .twitter-footer {
            overflow: hidden;

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

    &__media-hide {
        display: block;
    }

    &__media-show {
        display: none;
    }

    @include media('<=tablet-portrait') {
        &__social-list {
            padding-left: 1rem;
        }
    }

    @include media('>tablet-landscape','<desktop') {
        padding: 40px 40px 100px 40px;
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        padding: 40px 40px 100px 40px;

        &__security {
            font-size: 24px;
        }
    }

    @include media('>phone','<=tablet-portrait') {
        padding: 60px 20px 120px 20px;
    }

    @include media('<=phone') {
        padding: 60px 20px 120px 20px;
    }
}

@include media('<tablet-middle') {
    .clienthq-footer {
        &__media-hide {
            display: none;
        }

        &__media-show {
            display: block;
        }
    }
}

@include media('<tablet-portrait') {
    .clienthq-footer {
        &__media-hide {
            display: none;
        }

        &__media-show {
            display: block;          
            padding-left:80px;
            padding-top:20px;
        }
    }
}
@media print {
    .clienthq-footer {
        display: none;
        background: none;
    }
}
