.clienthq-infographic {
}

#i_1:checked ~ .controls label[for="i_1"],
#i_2:checked ~ .controls label[for="i_2"],
#i_3:checked ~ .controls label[for="i_3"],
#i_4:checked ~ .controls label[for="i_4"],
#i_5:checked ~ .controls label[for="i_5"],
#i_6:checked ~ .controls label[for="i_6"] {
    color: #7DD2D3;
}

#i_1:checked ~ .wrapper .content1,
#i_2:checked ~ .wrapper .content2,
#i_3:checked ~ .wrapper .content3,
#i_4:checked ~ .wrapper .content4,
#i_5:checked ~ .wrapper .content5,
#i_6:checked ~ .wrapper .content6 {
    opacity: 1;
    pointer-events: auto;
}
