.search-landing {
    &__results-container {
        background-color: $colors__gray--f7;
        padding: $spacing__gutter-width * 2 0;
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 22px;
        color: $colors__blue;
        margin-bottom: 30px;
    }

    &__applied-filters {
        flex: 1 1 100%;        
        order: 1;
    }

    &__applied-filters-item {
        list-style-type: none;
        display: inline-block;
        margin-right: 1em;
    }

    &__applied-filter {
        @include fonts__sans-serif-medium;
        border: 2px solid $colors__blue;
        border-radius: 4px;        
        line-height: 30px;
        padding: 5px 16px;
        font-size: 16px;
        display: block;
        color: $colors__blue;

        &::after {
            content: '';
            display: inline-block;
            height: 15px;
            width: 15px;
            background-size: 15px 15px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EF07DA36E-8D2A-4ADF-9FA9-E65A1BEAC63F@3x%3C/title%3E%3Cg id='MVP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg id='D---Homepage-with-Banner' transform='translate(-1258.000000, -18.000000)' stroke='currentColor' stroke-width='2'%3E%3Cg id='X' transform='translate(1259.000000, 19.000000)'%3E%3Cline x1='0.5' y1='0.5' x2='15.5' y2='15.5' id='Line'%3E%3C/line%3E%3Cline x1='0.5' y1='0.5' x2='15.5' y2='15.5' id='Line-Copy' transform='translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) '%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            margin-left: 24px;
            line-height: 30px;
            vertical-align: middle;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__meta {
        @include fonts__sans-serif-medium;
        color: $colors__blue;
        font-size: 1em;
    }

    &__clear {
        @include fonts__sans-serif-medium;
        color: $colors__blue;
        font-size: 1em;
        text-decoration: underline;
        margin-left: 24px;
    }

    &__prefs {
        background-color: $colors__gray--d8;
        padding: 8px 16px;
        font-size: 15px;
        display: inline-block;
        margin-top: 1em;
        margin-bottom: 1em;

        a {
            @include fonts__sans-serif-medium;
        }
    }

    &__sort {
        flex: 0 1 auto;
        order: 0;
        margin-bottom: auto;
        position: relative;
        z-index: 3;

        @include media('>=tablet-portrait') {
            flex: 0 1 210px;
            max-width: 210px;
            order: 1;
        }
    }
    
    &__sort-list {
        width: 100%;
        font-size: 16px;

        li {
            @include fonts__sans-serif-medium;
            list-style-type: none;
            a {
                width: 100%;
                padding: 13px 16px;
                display: block;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__sort-current {
        @include fonts__sans-serif-medium;
        padding: 13px 16px;
        display: flex;
        align-items: center;
        border: 2px solid $colors__blue;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;

        &:focus {
            outline: thin dotted $colors__blue;
        }

        span {
            @include fonts__sans-serif-bold;
            margin-left: 5px;
            margin-right: 10px;
        }

        &.active {
            &::after {
                transform: none;
            }
        }

        &::after {
            content: '';
            display: inline-block;
            height: 10px;
            width: 16px;
            background-size: 16px 10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='9px' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E61F65A17-1E11-4AB9-A1CC-BEDBC54F0C0D@3x%3C/title%3E%3Cg id='MVP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='D---Homepage-with-Banner' transform='translate(-642.000000, -130.000000)' stroke='currentColor' stroke-width='2'%3E%3Cg id='Navigation/Desktop/Main' transform='translate(0.000000, 54.000000)'%3E%3Cg id='Group-9' transform='translate(487.000000, 67.000000)'%3E%3Cpolygon id='Stroke-3-Copy-6' transform='translate(162.000000, 13.000000) rotate(-270.000000) translate(-162.000000, -13.000000) ' points='159 7 165 12.9991875 159 19 165 12.9991875'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            margin-left: auto;
            transform: rotate(180deg);
            transition: .3s ease-in-out transform;
        }
    }

    &__sort-options {
        border: 1px solid $colors__gray--d8;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
        position: relative;
        opacity: 0;
        pointer-events: none;
        transition: .3s ease-in-out all;
        height: 0;
        position: absolute;
        width: 100%;

        > ul {
            width: 100%;
        }

        &.active {
            opacity: 1;
            pointer-events: auto;
            height: auto;
        }

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid rgba(0,0,0,0);
            border-right: 10px solid rgba(0,0,0,0);
            border-bottom: 10px solid $colors__white;
            position: absolute;
            top: -10px;
            right: 1em;
        }
        &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 12px solid rgba(0,0,0,0);
            border-right: 12px solid rgba(0,0,0,0);
            border-bottom: 12px solid $colors__gray--d8;
            position: absolute;
            top: -11px;
            right: calc(1em - 2px );
        }
    }

    &__sort-option {
        position: relative;
        z-index: 1;
        background-color: $colors__white;
        margin-bottom: 0;
        border-bottom: 1px solid $colors__gray--d8;

        a {
            &:focus {
                outline-offset: -1px;
            }
        }

        &:first-child {
            border-radius: 4px 4px 0 0;
            overflow: hidden;
        }

        &:last-child {
            border-bottom: none;
            border-radius:  0 0 4px 4px;
            overflow: hidden;
        }

        &.active {
            @include fonts__sans-serif-bold;
        }
        &:hover {
            background-color: $colors__teal--lightest;
        }
    }

    &__mobile-wrapper {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }


    &__view-more {
        text-align: center;
        margin-top: 1em;
    }

    &__results {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    &__results-item {
        flex: 1 1 100%;
        list-style-type: none;
        margin: 15px;
        
    }
    
    @include media('>=phone') {
        &__results--three-column & {
            &__results-item {
                flex: 0 1 calc(50% - 30px);
            }
        }

        &__results--five-column & {
            &__results-item {
                flex: 0 1 calc(50% - 30px);
            }
        }
    }
    @include media('>=tablet-portrait') {
        &__results--three-column & {
            &__results-item {
                flex: 0 1 calc((100%/3) - 30px);
            }
        }

        &__results--five-column & {
            &__results-item {
                flex: 0 1 calc((100%/3) - 30px);
            }
        }
        &__applied-filters {
            flex: 1 1 auto;
            order: 0;
        }
        &__mobile-wrapper {
            flex-wrap: nowrap;
            text-align: left;
        }
    }
    @include media('>=tablet-middle') {
        &__results--five-column & {
            &__results-item {
                flex: 0 1 calc((100%/4) - 30px);
            }
        }   
    }
    @include media('>=desktop') {
        &__results--five-column & {
            &__results-item {
                flex: 0 1 calc((100%/5) - 30px);
            }
        }   
    }
}