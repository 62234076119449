.clienthq-preferences {
    &__container {
        &--headline {
            font-weight: 700 !important;
            @include fonts__serif-regular;
        }

        &--message {
            padding: 15px 0;
            font-weight: 500;
            font-size: 18px;
        }

        &--save-error {
            color: orangered;
        }

        &--success {
            color: green;
        }

        &__header {
            padding-top: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #c5c5c1;
            font-weight: bold;
            font-size: 19px;
        }

        &__checkboxlist {
            padding-top: 10px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 300px;

            & li {
                list-style-type: none;
                display: flex;
                align-items: center;

                & input[type="checkbox"] {
                    appearance: none;
                    -webkit-appearance: none;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    margin: 5px;
                    border: 0.2rem solid #1e2133;

                    &::before {
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        border-radius: 0;
                        clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
                        transition: 0.4s cubic-bezier(0.8, 0, 0.2, 1);
                        transition-property: background-color, clip-path, border-color;
                        transition-delay: 0.1s, 0s;
                    }

                    &:checked {
                        border: 0.2rem solid $colors__gray--ac;
                    }

                    &:checked::before {
                        background-color: green;
                        border-color: #1e2133;
                        transition-delay: 0s;
                        clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
                    }
                }

                & label {
                    color: #1e2133;
                    padding-left: 5px;
                }
            }
        }
    }

    & a {
        text-decoration: none;
    }
}
